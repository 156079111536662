<template>
  <c-4-page>
    <template v-slot:header>{{ $t("feedback.l.page") }}</template>

    <template v-slot:body>
      <div class="w3-container">
        <div class="w3-container w3-section">
          <div
            class="w3-large"
            style="white-space: pre-wrap"
            v-t="'feedback.m.success'"
          />
        </div>

        <div class="w3-container w3-padding w3-center w3-large">
          <i class="fas fa-info-circle w3-padding-small w3-text-blue"></i>
          <i18n-t
            tag="span"
            keypath="feedback.m.successStatus"
            scope="global"
          >
            <strong>{{ $t("feedback.m.status") }}</strong>
          </i18n-t>
        </div>

        <i18n-t
          v-if="props.anonymous"
          class="w3-container w3-section"
          tag="div"
          keypath="feedback.m.statusAnonymous"
          scope="global"
        >
          <strong>{{ $t("feedback.m.statusCheck") }}</strong>
        </i18n-t>
        <i18n-t
          v-else-if="!props.email"
          class="w3-container w3-section"
          tag="div"
          keypath="feedback.m.statusNoEmail"
          scope="global"
        >
          <strong>{{ $t("feedback.m.statusCheck") }}</strong>
        </i18n-t>

        <div class="w3-row-padding w3-section">
          <div class="w3-col">
            {{ $t("feedback.l.caseId") }}
            <b class="w3-monospace">{{ props.token }}</b>
            <button
              class="c4-btn-flat c4-copy-effect c4-screen-only"
              type="button"
              :title="$t('feedback.t.caseId')"
              @click="copyToClipboard"
            >
              <i class="far fa-clipboard w3-large"></i>
            </button>
          </div>

          <div class="w3-col c4-screen-only w3-padding-small">
            <label v-t="'feedback.l.password'" />
            <PasswordInput
              class="w3-rest"
              :value="props.password"
              :disabled="true"
              :button-title="$t('common.t.password')"
            />
          </div>
        </div>

        <!-- Provide user with a login link -->
        <div class="w3-row-padding w3-section">
          <i18n-t
            keypath="feedback.m.login"
            tag="div"
            class="w3-col"
          >
            <a
              :href="
                loginUri + '?lang=' + $i18n.locale + '&case=' + props.token
              "
              target="_blank"
              rel="noopener"
            >
              {{ loginUri }}
            </a>
          </i18n-t>
        </div>

        <div class="w3-row-padding w3-section">
          <div
            class="w3-col"
            v-t="'feedback.m.info'"
          />
        </div>

        <!-- Show user a summary of what will be sumbitted -->
        <div class="w3-row-padding">
          <div class="w3-col w3-section">
            <b><label v-t="'feedback.l.summary'" /></b>
            <span
              v-if="props.anonymous"
              v-t="'feedback.l.anonymous'"
            />
            <div
              class="w3-padding-16"
              ref="summary"
            >
              <div
                v-for="(a, name) in props.summary"
                :key="name"
              >
                <div
                  v-if="a"
                  class="w3-blue w3-row-padding"
                  v-t="name"
                />
                <div
                  style="white-space: pre-wrap"
                  v-html="a"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:nav>
      <!-- Show a "print this page" button -->
      <div class="w3-padding w3-center c4-screen-only">
        <p
          class="w3-light-blue w3-large"
          v-t="'feedback.m.print'"
        />
        <button
          type="button"
          class="w3-btn w3-round-xxlarge w3-xxlarge w3-blue"
          @click="printPage"
        >
          <i class="fas fa-print"></i>
        </button>
      </div>
    </template>
  </c-4-page>
</template>

<script>
import C4Page from "@/components/C4Page.vue";
import PasswordInput from "@/components/PasswordInput.vue";

export default {
  components: { C4Page, PasswordInput },
  name: "C4FeedbackPage",
  props: {
    props: Object,
  },
  data() {
    return {
      loginUri: window.location.origin + "/login",
    };
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.props.token);
    },
    printPage() {
      window.print();
    },
  },
};
</script>

<style>
.c4-copy-effect:active {
  transform: scale(1.8);
  transition: 1s cubic-bezier(0, 2.64, 0.57, 0.33) 0s;
}
@media print {
  .c4-screen-only {
    display: none;
  }
}
</style>
