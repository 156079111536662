/**
 * Keys used
 * m: guiding message on a page
 * l: label for a form field
 * t: tooltip for a form field
 * info: information, e.g. in a select control or as a placeholder
 * opt: option in a select control
 * btn: label for a button
 */

export default {
  /**
   * Commonly used strings
   */
  common: {
    m: {
      required: "Füllen Sie die erforderlichen Felder aus.",
    },
    info: {
      select: "Auswählen...",
      other: "Sonstiges:",
      noEntries: "Keine Einträge",
    },
    l: {
      required: "(erforderlich)",
      optional: "(optional)",
      yes: "Ja",
      no: "Nein",
      unknown: "Weiß nicht",
      password: "Passwort",
      mailboxId: "Postfach-Nummer",
    },
    btn: {
      back: "Zurück",
      next: "Weiter",
      cancel: "Abbrechen",
      submit: "Absenden",
      human: "Ich bin kein Computer",
      login: "Anmelden",
      audio: "Eine Nachricht aufnehmen",
      text: "Eine Nachricht schreiben",
      sendMessage: "Nachricht senden",
    },
    t: {
      password: "Passwort anzeigen / verbergen",
      inbound: "Empfangene Nachricht",
      outbound: "Gesendete Nachricht",
      chatInput: "Geben Sie eine Nachricht ein",
    },
  },
  /**
   * Application strings
   */
  app: {
    /**
     * Do NOT translate all languages, but your language ONLY!
     * Codes for country flag symbols can be found on https://www.alt-codes.net/flags
     * Pick the codes from the HTML Dec Code column and format them like that:
     * &#code1;&#code2;&#code3;
     * Be sure to have NO space inbetween the codes. If only one code, format only one,
     * if two or more codes, format them accordingly.
     */
    lang: {
      bg: "&#127463;&#127468; Български",
      de: "&#127465;&#127466; Deutsch",
      en: "&#127468;&#127463; English",
      es: "&#127466;&#127480; Español",
      fr: "&#127467;&#127479; Français",
      it: "&#127470;&#127481; Italiano",
      sl: "&#127480;&#127472; Slovenski",
    },
    btn: {
      logout: "Abmelden",
    },
    l: {
      imprint: "Impressum",
      privacy: "Datenschutz",
    },
    t: {
      logout: "Abmelden",
    },
  },
  login: {
    l: {
      header: "An sicherem Postfach anmelden",
      username: "Postfach-Nummer",
      password: "Passwort",
    },
    m: {
      error: "Token oder Passwort ungültig.",
    },
  },
  /**
   * Portal view
   */
  portal: {
    l: {
      view: "Willkommen auf dem sycoBASE Whistleblower Portal",
      search: "Eine Organisation finden:",
      login: "Anmelden an Ihrem Postfach:",
    },
    m: {
      intro:
        "Das Hinweisgebersystem dient dazu, Hinweise von (mutmaßlichen) Gesetzes- oder schweren internen Regelverletzungen gegen die Organisation auf einem sicheren und vertraulichen Weg entgegenzunehmen und zu bearbeiten. Organisationen sind verpflichtet solchen Meldungen, auch anonymen, nach zu gehen und Sie als Meldende:n über die eingeleiteten Maßnahmen zu informieren. Ihre Daten werden hierbei absolut vertraulich behandelt und sind Ende-zu-Ende verschlüsselt.",
      search:
        "Hier haben Sie die Möglichkeit nach Organisationen zu suchen, die ihre Seite in unserem sycoBASE Whistleblower Portal veröffentlicht haben.",
      login:
        "Falls Sie bereits eine Meldung übermittelt haben, können Sie sich hier an Ihrem sicheren Postfach anmelden und Informationen zu Ihrer Meldung erhalten.",
      noentry:
        "Wir konnten leider keine Organisation mit diesem Suchbegriff finden.",
    },
    t: {
      openLink: "Hinweisgebersystem von {0} öffnen",
    },
  },
  /**
   * Inbox view
   */
  inbox: {
    l: {
      view: "Ihre Mitteilungen",
      viewShort: "Mitteilungen",
    },
    btn: {
      message: "Mitteilung senden",
      upload: "Dateien hochladen",
    },
  },
  /**
   * Composer view
   */
  composer: {
    l: {
      header: "Eine Mitteilung senden",
    },
    m: {
      info: "Sie können eine Mitteilung an Fall anhängen.",
    },
  },
  /**
   * Upload view
   */
  upload: {
    l: {
      header: "Dateien anhängen",
    },
    m: {
      info: "Sie können Dateien an den Fall anhängen.",
    },
  },
  /**
   * General wizard page strings
   */
  general: {
    m: {
      welcome:
        "Hier können Sie Beobachtungen oder Verdachtsfälle von Fehlverhalten sicher melden. Durch das Absenden Ihrer Meldung wird automatisch ein sicheres Postfach erstellt, das auch bei anonymer Meldung eine fortlaufende Kommunikation ermöglicht.",
      anonymous:
        "Ihre Meldung erfolgt anonym, und Ihre Identität wird nicht an die Organisation weitergeleitet.\nEine automatische Benachrichtigung über Änderungen an Ihrer Meldung ist daher nicht möglich. Sie können den Status des Falles nur über das sichere Postfach einsehen.",
      identified:
        "Ihre Meldung ist ausschließlich für diejenigen einsehbar, die mit der Bearbeitung des Falles beauftragt sind. Ihre Identität wird nur den Personen bekannt, die den Fall vertraulich bearbeiten.\nUm automatische Benachrichtigungen über Änderungen am Fall zu erhalten, geben Sie bitte eine E-Mail-Adresse an. Falls keine E-Mail-Adresse angegeben wird, können Sie den Status des Falles nur über das sichere Postfach einsehen.",
    },
    l: {
      page: "Angaben zur Organisation",
      step: "Organisation",
      company: "Dieser Hinweis bezieht sich auf die Organisation",
      relation: "Wie ist Ihre Beziehung zu dieser Organisation?",
      anonymous: "Anonym melden",
      identified: "Vertraulich melden",
    },
    opt: {
      employee: "Organisationsangehörige:r",
      customer: "Beschäftigte:r eines Kunden",
      supplier: "Beschäftigte:r eines Lieferanten",
    },
  },
  /**
   * User info wizard page strings
   */
  userinfo: {
    m: {
      employee: "Sie sind Organisationsangehörige:r",
    },
    l: {
      page: "Persönliche Angaben",
      step: "Persönliche Angaben",
      salutation: "Anrede",
      title: "Titel",
      firstname: "Vorname",
      lastname: "Name",
      address: "Adresse",
      phone: "Telefonnummer",
      email: "E-Mail-Adresse",
      location: "Standort",
      department: "Abteilung",
      role: "Funktion",
    },
    opt: {
      salutationMale: "Herr",
      salutationFemale: "Frau",
      title1: "Prof.",
      title2: "Dr.",
    },
  },
  /**
   * Case info wizard page strings
   */
  caseinfo: {
    l: {
      page: "Sachverhaltsbeschreibung",
      step: "Sachverhalt",
      area: "Wählen Sie den Schwerpunkt aus, der auf den Inhalt Ihrer Meldung am besten zutrifft.",
      when: "Seit wann besteht die Situation auf die sich der Hinweis bezieht?",
      where: "Wo hat sich der Vorfall ereignet?",
      ongoing: "Dauert der Vorfall noch an?",
      awareness: "Haben Sie den Vorfall selbst beobachtet?",
      description:
        "Wie lässt sich der Vorfall belegen? Sie helfen uns wenn Sie den Vorfall so detailliert wie möglich beschreiben.",
      descriptionShort: "Beschreiben Sie den Vorfall.",
      involvedSelf: "Sind Sie persönlich in den Vorfall involviert?",
      involvedPeople: "Welche Personen sind an dem Vorfall beteiligt?",
      mgmtInformed: "Ist das Management über den Vorfall informiert?",
      upload:
        "Sie können Dateien bis zu {0} MB Größe hier hochladen. In Ihrem sicheren Postfach haben Sie die Möglichkeit weitere Dateien bereit zu stellen.",
      uploadWarn:
        "Beachten Sie, dass einige Dateitypen, wie z.B. Word, PDF und Bilder, aber auch andere, Meta-Informationen enthalten, die auf Ihre Identität hinweisen könnten. Entfernen Sie diese Meta-Informationen bevor Sie die Dateien hochladen.",
      files: "Dateien",
    },
    opt: {
      area1: "Korruption, Bestechung, Interessenskonflikte",
      area2: "Unterschlagung, Veruntreuung, Diebstahl, Betrug",
      area3: "Verstoß gegen Datenschutz und IT-Sicherheit",
      area4: "Verstoß gegen Arbeits- und Gesundheitsschutzvorschriften",
      area5: "Verstoß gegen Umweltschutzvorschriften",
      area6:
        "Verstoß gegen Wettbewerbs- und Kartellrecht, Verrat von Geschäftsgeheimnissen",
      area7: "Verstoß gegen Geldwäschegesetz",
      area8:
        "Verstoß gegen Rechnungslegungs- oder Buchführungsvorschriften, steuerrechtliche Sachverhalte",
      area9: "Verletzung von internen Richtlinien/Code of Conduct",
      area10:
        "Belästigung, Diskriminierung, Mobbing, Allgemeines Gleichbehandlungsgesetz",
    },
    btn: {
      text: "Ich möchte schriftlich melden",
      audio: "Ich möchte mündlich melden",
    },
    m: {
      audio: "Tonaufnahme vorhanden",
    },
  },
  /**
   * Submit wizard page strings
   */
  submit: {
    l: {
      page: "Angaben überprüfen und senden",
      step: "Absenden",
      password:
        "Geben Sie ein Passwort ein mit dem Sie Ihr sicheres Postfach schützen",
      passwordConfirm: "Bestätigen Sie Ihr Passwort",
      passwordRules: "Das Passwort muss folgendes enthalten:",
      passwordLetter: "Einen <b>Klein</b>buchstaben",
      passwordCapital: "Einen <b>Groß</b>buchstaben",
      passwordNumber: "Eine <b>Ziffer</b>",
      passwordLength: "Mindestens <b>8 Zeichen</b>",
      summary: "Ihre Eingaben:",
      anonymous: "Sie geben Ihren Hinweis anonym.",
      consentFile: "Ich habe die $file{'{'}{0}{'}'} zur Kenntnis genommen",
      consentLink: "Ich habe die $link{'{'}{0}{'}'} zur Kenntnis genommen",
      privacyWord: "Datenschutzhinweise",
    },
    m: {
      info: "Nachdem Sie Ihre Meldung abgesendet haben, wird sie vertraulich behandelt. Besuchen Sie die Plattform jederzeit, um mögliche Updates einzusehen. Schauen Sie regelmäßig in Ihren Posteingang für weiterführende Informationen oder Fragen.",
      noEmail:
        "Sie haben keine E-Mail-Adresse angegeben. Übermitteln Sie uns Ihre E-Mail-Adresse, um automatisch benachrichtigt zu werden.",
      access:
        "Für den Zugang zu Ihrem sicheren Postfach benötigen Sie Ihre Postfach-Nummer und das von Ihnen gewählte {0}. Die {1} wird Ihnen nach erfolgreicher Übermittlung Ihrer Eingaben angezeigt.",
      nomatch: "Die Passworte stimmen nicht überein.",
      cancel: "Alle Eingaben gehen verloren. Wirklich abbrechen?",
    },
  },
  /**
   * Robots wizard page strings
   */
  robots: {
    l: {
      page: "Sind Sie ein Mensch?",
    },
    m: {
      error: "Die Sicherheitsüberprüfung kann nicht angezeigt werden.",
      info: "Geben Sie den unten stehenden Code ein um Spam zu vermeiden.",
      human: "Sie scheinen kein Computer zu sein!",
      nohuman: "Der Code ist nicht korrekt. Probieren Sie es noch einmal.",
    },
    t: {
      generate: "Neuen Code erzeugen",
    },
  },
  /**
   * Feedback wizard page strings
   */
  feedback: {
    l: {
      page: "Zusammenfassung",
      step: "Check-Out",
      caseId: "Notieren Sie sich Ihre Postfach-Nummer:",
      password: "Das Passwort zum Schutz Ihrer Mailbox lautet",
      summary: "Sie haben folgende Eingaben übermittelt:",
      anonymous: " (anonym)",
    },
    m: {
      success:
        "Sie haben Ihren Hinweis erfolgreich übermittelt.\nVielen Dank für Ihre Unterstützung!",
      successStatus: "Ihre Meldung befindet sich jetzt {0}.",
      status: "in Bearbeitung",
      statusNoEmail:
        "Da Sie keine E-Mail-Adresse angegeben haben, können wir Sie über neue Mitteilungen nicht aktiv informieren. {0}",
      statusEmail:
        "Sobald wir neue Informationen haben, werden wir Sie benachrichtigen.",
      statusAnonymous:
        "Da Sie von der anonymen Meldemöglichkeit Gebrauch gemacht haben, können wir Sie über neue Mitteilungen nicht aktiv informieren. {0}",
      statusCheck:
        "Prüfen Sie deshalb Ihr Postfach regelmäßig auf neue Nachrichten.",
      info: "Sie benötigen Ihre Postfach-Nummer und Ihr Passwort, um auf Ihr sicheres Postfach zugreifen zu können. Falls Sie Ihre Postfach-Nummer oder Ihr Passwort vergessen haben können Sie nicht mehr auf den Vorfall zugreifen. Aus Sicherheitsgründen und zum Schutz Ihrer Anonymität können wir Ihre Postfach-Nummer oder Ihr Passwort nicht wiederherstellen.",
      login:
        "Sie können sich mit diesem Link {0}, Ihrer Postfach-Nummer und Ihrem Passwort an Ihrem sicheren Postfach anmelden.",
      print:
        "Drucken Sie diese Seite aus, oder speichern Sie sie als PDF, damit Sie Ihre Daten bequem zur Hand haben.",
    },
    t: {
      caseId: "In die Zwischenablage kopieren",
    },
  },
  error: {
    l: {
      page: "Es ist ein Fehler aufgetreten",
      errorSummary: "Sie haben folgendes eingegeben:",
    },
    h: {
      error404: "Fehler 404",
      error502: "Fehler 502",
    },
    m: {
      error404: "Diese Seite kann auf unserem Server nicht gefunden werden.",
      error502: "Der Server antwortet nicht. Versuchen Sie es in Kürze erneut.",
      uploadSize: "Die Datei(en) überschreiten die zulässige Größe von {0} MB.",
      submitError:
        "Es tut uns leid, aber bei der Übermittlung Ihrer Daten ist ein nicht behebbarer Fehler aufgetreten.",
      errorInfo:
        "Wir haben Ihre Eingaben nochmals aufgeführt, damit Sie sie sichern können. Bitte versuchen Sie eine erneute Eingabe zu einem späteren Zeitpunkt.",
      errorThanks: "Vielen Dank für Ihr Verständnis.",
    },
  },
  types: {
    audio: {
      m: {
        stopped: "Aufnahme beendet",
        start: "Aufnahme nicht gestartet",
        recording: "Aufnahme läuft",
        paused: "Aufnahme pausiert. Drücken Sie Stopp zum beenden der Aufnahme",
      },
      t: {
        stop: "Aufnahme beenden",
        pause: "Pause",
        unpause: "Aufnahme fortsetzen",
        record: "Aufnahme starten",
        discard: "Aufnahme löschen",
      },
    },
    upload: {
      l: {
        select: "Datei wählen oder hierher ziehen",
      },
      m: {
        uploadSize: "Dateien bis zu {0} MB",
        remaining: "{0} MB verbleiben",
        singleFile: "Es kann nur eine Datei hochgeladen werden",
      },
    },
  },
  date: {
    at: {
      sent: "Am {0} gesendet",
      received: "Am {0} erhalten",
    },
  },
};
